
export default {
  props: {
    item: Object,
    isNested: {
      type: Boolean,
      default: false
    }
  },
};
