import { render, staticRenderFns } from "./LayoutControlNav.vue?vue&type=template&id=5676ce84&scoped=true"
import script from "./LayoutControlNav.vue?vue&type=script&lang=js"
export * from "./LayoutControlNav.vue?vue&type=script&lang=js"
import style0 from "./LayoutControlNav.vue?vue&type=style&index=0&id=5676ce84&prod&scoped=true&lang=css"
import style1 from "./LayoutControlNav.vue?vue&type=style&index=1&id=5676ce84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5676ce84",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasicLink: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/basic/BasicLink.vue').default})
