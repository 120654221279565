import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5c0113bc"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=5c0113bc&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeader: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/layout/LayoutHeader.vue').default,LayoutNav: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/layout/LayoutNav.vue').default,LayoutControlForm: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/layout/LayoutControlForm.vue').default,LayoutControlBack: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/layout/LayoutControlBack.vue').default,LayoutNotification: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/layout/LayoutNotification.vue').default})
