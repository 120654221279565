
import _throttle from 'lodash.throttle'
import routeProperties from '@/mixins/routeProperties'
import { gsap } from "gsap/dist/gsap.js"

export default {
  props: {
    isHome: Boolean
  },

  mixins: [ routeProperties ],

  data () {
    return {
      isVisible: true,
      opacity: {
        val: 0
      }
    }
  },

  methods: {
    handleScroll () {
      // this.opacity.val = 1 - (window.scrollY / (window.innerHeight * 0.5))
    },
    show () {
      gsap.to(this.opacity, { duration: 0.8, val: 1, delay: 0.7, ease: "Power2.in" })
    }
  },

  watch: {
    isOverlaySeen (val) {
      if (val) this.show()
    }
  },

  computed: {
    isOverlaySeen () {
      return this.$store.state.layout.isOverlaySeen
    }
  },

  mounted () {
    if (this.isOverlaySeen) this.show()
    // const el = document.querySelector('[layout-background-carousel-track]')
    // if (el) {
    //   // el.addEventListener('scroll', this.handleScroll)
    // } else {
    //   window.addEventListener('scroll', this.handleScroll)
    // }
  },

  beforeDestroy () {
    // const el = document.querySelector('[layout-background-carousel-track]')
    // if (el) {
    //   // el.removeEventListener('scroll', this.handleScroll)
    // } else {
    //   window.removeEventListener('scroll', this.handleScroll)
    // }
  }
}
