
import pageProperties from '@/mixins/pageProperties.js'
import { gsap } from "gsap/dist/gsap.js"
import _debounce from 'lodash.debounce'

export default {
  props: {
    isBig: Boolean
  },

  mixins: [
    pageProperties
  ],

  data() {
    return {
      pages: null,
      marker: {
        opacity: 0,
        x: 0,
        width: 0
      },
      menu: {
        opacity: {
          val: 0
        }
      }
    }
  },

  fetchKey: 'SnippetMenu',
  async fetch() {
    this.pages = await this.$content(this.$i18n.locale, { deep: true })
      .where({
        $and: [
          { published : { $eq: true } },
          { 'parent.template': { $eq: 'home' } },
          { template: { $ne: 'more' } },
          { template: { $ne: 'blog' } },
          { template: { $ne: 'utility' } },
        ],
      })
      .sortBy('sorting')
      .only(['title', 'slug', 'id', 'group', 'locale', 'multilingual'])
      .fetch();
    },

  computed: {
    isOverlaySeen () {
      return this.$store.state.layout.isOverlaySeen
    }
  },

  methods: {
    handleMarker: _debounce(function () {
      const active = this.$refs.root.querySelector('.--active')
      if (active) {
        const box = active.getBoundingClientRect()
        const rootBox = this.$refs.root.getBoundingClientRect()
        const left = box.left - rootBox.left
        this.marker.opacity = 1
        this.marker.width = `${box.width}px`
        this.marker.x = `calc(${left}px - 1rem - 1px)`
      } else {
        this.marker.opacity = 0
      }
    }, 200),
    show () {
      gsap.to(this.menu.opacity, { duration: 0.8, val: 1, delay: 1.1, ease: "Power2.in" })
      setTimeout(() => {
        if (!this.isBig) this.handleMarker()
      }, 1100)
    }
  },

  watch: {
    '$route.path': function () {
      console.log('PATH')
      if (!this.isBig) this.handleMarker()
    },
    isOverlaySeen (val) {
      if (val) this.show()
    }
  },

  mounted () {
    if (this.isOverlaySeen) this.show()
    if (!this.isBig) window.addEventListener('resize', this.handleMarker)
  },
  beforeDestroy () {
    if (!this.isBig) window.removeEventListener('resize', this.handleMarker)
  }
}
