
  import { theme } from '@/tailwind.config.js'
  import pageTransition from '@/mixins/pageTransition'
  import routeProperties from '@/mixins/routeProperties'
  import { gsap } from "gsap/dist/gsap.js"

  export default {
    data () {
      return {
        opacity: {
          val: 0
        }
      }
    },

    mixins: [ pageTransition, routeProperties ],

    watch: {
      '$route.path': function () {
         if (this.isNavigationVisible) {
           this.$store.commit('nav/setIsNavigating')
           this.$store.commit('nav/toggleIsVisible')
         }
       },
       isOverlaySeen (val) {
         if (val) this.show()
       }
    },

    methods: {
      show () {
        gsap.to(this.opacity, { duration: 0.8, val: 1, delay: 0.9, ease: "Power2.in" })
      }
    },

    computed: {
      isNavigationVisible () {
        return this.$store.state.nav.isVisible
      },
      isHelperVisible () {
        return this.$store.state.nav.isHelperVisible
      },
      isOverlaySeen () {
        return this.$store.state.layout.isOverlaySeen
      }
    },

    mounted () {
      if (this.isOverlaySeen) this.show()
      document.documentElement.style.setProperty('--tw-duration-page', theme.transitionDuration.page)
    }
  }
