
import routeProperties from '@/mixins/routeProperties'

export default {
  mixins: [ routeProperties ],

  computed: {
    isFrameVisible () {
      return this.$store.state.layout.isFrameVisible
    },
    backgroundColor () {
      return this.$store.state.layout.backgroundColor
    },
  },

  watch: {
    backgroundColor: {
      immediate: true,
      handler (val) {
        if (process.client) {
          const el = document.querySelector('html')
          el.className = ''
          el.classList.add('transition-colors', 'duration-page', `bg-${val}`)
        }
      }
    }
  },

  async mounted () {
    console.log(`

                      _~
                   _~(_(_~
                  (_((_((_(
                  _!__!__!_
                  \\_______/
        ~  ~~~~ STUDIOFFF.EU ~~~~  ~~~~~~~~
      ~~~   ~~~    ~~~~~~~~~~~   ~~~~~~~  ~~
      `);
  },
};
